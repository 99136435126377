import React from 'react'

const Explore = () => {
  return (
    <div className="p-3 mb-3">
      <h4>Explore</h4>
    </div>
  )
}

export default Explore
